import LanguageSwitcher from '~/themes/base/components/shop/LanguageSwitcher'

export default {
  extends: LanguageSwitcher,
  computed: {
    languages() {
      const links = []
      this.$store.state.globals.languages.forEach(language => {
        const { code, native } = language || {}
        const { url, sameDomain } = this.getLanguageURL(code)
        if (url) {
          links.push({
            code,
            href: this.localePath('index', code),
            native,
            sameDomain,
          })
        }
      })
      return links
    },
  },
}
