var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.dropdown && _vm.languages.length > 1)?_c('div',{staticClass:"LanguageSwitcher LanguageSwitcher--dropdown"},[_c('span',{staticClass:"LanguageSwitcher__selected link link-underlined",attrs:{"id":_vm.dropdownId}},[_vm._t("default",function(){return [(_vm.switcherType === 'flag')?_c('svgicon',{staticClass:"LanguageSwitcher__selectedFlag",attrs:{"icon":_vm.icons[_vm.activeLanguage.code]}}):_vm._e(),_c('span',{domProps:{"innerHTML":_vm._s(_vm.shortSelectedName ? _vm.getShortName(_vm.activeLanguage.code) : _vm.activeLanguage.native)}}),(_vm.iconOpen)?_c('svgicon',{staticClass:"svg-icon-offset-left flex-shrink-0",attrs:{"icon":_vm.iconOpen,"fill":""}}):_vm._e()]})],2),_c('Dropdown',{ref:"dropdown",attrs:{"options":{ placement: 'bottom-end' },"trigger":_vm.dropdownId,"type":_vm.dropdownTriggerType}},[_c('nav',{staticClass:"LanguageSwitcher__items",class:{
        '---isLoading': _vm.loading,
        'LanguageSwitcher__items--flags': _vm.switcherType === 'flag',
        'LanguageSwitcher__items--icons': _vm.switcherType !== 'flag' &&
          _vm.iconActive &&
          _vm.iconInactive
      }},[(_vm.loading)?_c('Loader',{staticClass:"Loader__loader--small",attrs:{"no-center":true}}):_vm._l((_vm.languages),function(language){return _c('a',{key:language.code,staticClass:"LanguageSwitcher__item",class:{
          'LanguageSwitcher__item--active': language.code === _vm.activeLanguage.code,
          'd-none': _vm.showActiveLanguage === false && language.code === _vm.$store.state.locale.contentLocale
        },attrs:{"href":language.href,"target":!language.sameDomain ? '_blank' : false,"title":language.code}},[(_vm.switcherType === 'flag')?_c('svgicon',{attrs:{"icon":_vm.icons[language.code]}}):_vm._e(),_c('span',[_vm._v(_vm._s(language.native))]),(_vm.iconActive && _vm.iconInactive)?_c('svgicon',{attrs:{"icon":language.code === _vm.activeLanguage.code ? _vm.iconActive : _vm.iconInactive}}):_vm._e()],1)})],2)])],1):(!_vm.dropdown && _vm.$i18n.locales.length > 0 && _vm.languages.length > 1)?_c('nav',{staticClass:"LanguageSwitcher LanguageSwitcher--inline"},_vm._l((_vm.languages),function(language){return _c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.showTooltip && language.native),expression:"showTooltip && language.native"}],key:language.code,staticClass:"LanguageSwitcher__item",class:{
      'LanguageSwitcher__item--active': language.code === _vm.$i18n.locale,
      'd-none': _vm.showActiveLanguage === false && language.code === _vm.$i18n.locale
    },attrs:{"href":language.href,"target":!language.sameDomain ? '_blank' : false},domProps:{"innerHTML":_vm._s(language.native)}})}),0):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }